  .dot-selector-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
  }
  
  .dot-selector-item {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    border: 2px solid transparent;
  }
  
  .dot-selector-item.selected {
    border-color: blue; /* Highlight when selected */
  }